"use client";

import { motion } from "framer-motion";
import { usePathname, useRouter } from "next/navigation";
import { ReactNode, useEffect } from "react";

export default function AuthLayout({ children }: { children: ReactNode }) {
  const router = useRouter();

  useEffect(() => {
    // this guy listens to see if we logout or log in, and reacts accordingly for each tab.
    const onStorageChange = async (event: StorageEvent) => {
      if (event.key === "q-state") {
        // If the user logs out, reload tab to be prompted to /login page
        if (event.oldValue && !event.newValue) {
          router.push("/logout");
        }
        // If user logs in, lets push out of the login page
        if (!event.oldValue && event.newValue) {
          router.push("/");
        }
      }
    };

    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, [router]);

  return (
    <motion.div
      key={usePathname()} // Add this key to trigger animation on route change
      initial={{ opacity: 0 }} // Start invisible
      animate={{ opacity: 1 }} // Animate to visible
      exit={{ opacity: 0 }} // Exit by fading out
      transition={{ duration: 0.3, ease: "easeInOut" }} // Smooth easing
      data-testid="app-root"
    >
      <div className="AuthLayout container md:max-w-7xl">{children}</div>
    </motion.div>
  );
}
